<template>
  <div class="main_style">
    <div class="header">
      <div class="header-text">
        <p class="header-title">众享线上交易平台</p>
        <p class="header-desc">
          基于区块链技术打造可靠的分布式资产管理平台，包含资产发行及管理、自动撮合管理、信息认证、充值提现、账单查询、人员管理等功能。
        </p>
      </div>
    </div>
    <!-- 产品介绍 -->
    <div class="intro">
      <div class="commom-title">方案介绍</div>
      <div class="intro-content">
        <p>
          众享区块链线上管理平台，旨在建立分布式管理市场，平台管理方、买方和发行方以各自身份加入系统。<br/><br/>在资产发行与流通网络中，区块链用于资产登记、管理确认、记账对账和清算等。<br/><br/>平台支持数字资产管理（IEO管理、场外C2C点对点管理、OTC管理、合约管理）、实物资产管理等多种管理模式，无限制对接各种类型，采用撮合管理+点对点方案，提供快速秒级管理引擎，稳定，安全，可靠管理方负责平台的维护与运营。<br/><br/>在传统的管理模式中，资产进入流通后，仍必须依赖资产发行方系统才能完成使用、转移，这就将资产流通范围限制在发行方系统用户内。应用区块链技术使资产流通由原来的单中心控制变为社会化流通，任何有资源的渠道都可以成为资产流通的催化剂，促进流通、提高流通效率。且区块链“管理即结算”的基本特性使得实时清算成为可能，大幅提高管理后处理的效率，实现资产流通情况的实时查询功能。<br/><br/>方案适用于数字资产管理、能源管理、版权管理、奢侈品管理、艺术品管理、精准扶贫等众多场景。
        </p>
      </div>
    </div>
    <!-- 产品架构 -->
    <div class="framework">
      <div class="commom-title">方案架构</div>
      <div class="framework-content">
        <img src="https://image.peerfintech.cn/peerfintech_website_v2/solution/transaction/framework.png" />
      </div>
    </div>
    <!-- 产品优势 -->
    <div class="advantage">
      <div class="commom-title">受益角色</div>
      <div class="advantage-content">
        <el-row :gutter="24">
          <el-col :span="12"
            ><div class="grid-content">
              <div class="img">
                <img src="@/assets/img/solution/transaction/role-1.png" />
              </div>
              <div class="title">平台管理方</div>
              <div class="text">
                基于区块链的分布式管理平台天然地具有技术信任属性，管理真实有效、可查询追溯，能有效解决传统管理平台数据不透明或数据造假、中心化平台易受攻击等问题，大大地降低投资风险
              </div>
            </div></el-col
          >
          <el-col :span="12"
            ><div class="grid-content">
              <div class="img">
                <img src="@/assets/img/solution/transaction/role-2.png" />
              </div>
              <div class="title">发行方</div>
              <div class="text">
                发行资产受众更广，且资产链上登记，保证产权的唯一性；撮合管理进行变现，每笔管理链上可查，避免纠纷；近乎“实时结算”，有效解决对账繁琐，对账效率低等问题。
              </div>
            </div></el-col
          >
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12"
            ><div class="grid-content">
              <div class="img">
                <img src="@/assets/img/solution/transaction/role-3.png" />
              </div>
              <div class="title">买方用户</div>
              <div class="text">
                获取真实可靠资产信息，管理透明、可追溯
              </div>
            </div></el-col
          >
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    // 页面回退顶部
    window.scrollTo(0, 0);
  },
};
</script>
<style lang="scss" scoped>
.main_style {
  background-color: #ffffff;
  .content-width {
    width: 1200px;
    margin: 0px auto;
  }
  .commom-title {
    padding: 70px 0px 50px;
    font-size: 32px;
    font-family: "PingFangSC-Medium";
    color: rgba(0, 0, 0, 0.85);
    line-height: 45px;
    text-align: center;
  }
  .header {
    width: 100%;
    height: 540px;
    background: url("https://image.peerfintech.cn/peerfintech_website_v2/solution/transaction/bg.png")
      center/cover no-repeat;
    .header-text {
      @extend .content-width;
      .header-title {
        font-size: 36px;
        font-family: "PingFangSC-Medium";
        color: #000000;
        line-height: 50px;
        padding-top: 160px;
      }
      .header-desc {
        width: 510px;
        color: rgba(0,0,0,0.75);
        font-size: 20px;
        line-height: 33px;
        margin-top: 36px;
      }
    }
  }
  .intro {
    .intro-content {
      @extend .content-width;
      font-size: 18px;
      color: rgba(0, 0, 0, 0.65);
      line-height: 25px;
    }
  }
 
  .framework {
    padding-bottom: 70px;
    .framework-content {
      @extend .content-width;
      img {
        width: 100%;
      }
    }
  }
  .advantage {
    background: #f4f7fc;
    .advantage-content {
      @extend .content-width;
      padding-bottom: 70px;
      .el-row {
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .grid-content {
        height: 370px;
        background: #ffffff;
        box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.1);
        padding: 40px 48px 0px 40px;
        .img {
          width: 124px;
          height: 124px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .title {
          padding: 24px 0px;
          font-size: 22px;
          font-family: "PingFangSC-Medium";
          color: rgba(0, 0, 0, 0.85);
          line-height: 30px;
        }
        .text {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.45);
          line-height: 22px;
        }
      }
    }
  }
}
</style>
